<button mat-icon-button [matMenuTriggerFor]="notificationMenu" (click)="onOpenMenu($event)">
  <mat-icon>notifications</mat-icon>
  <span *ngIf="unreadCount > 0"
    class="absolute -top-[2px] -right-1 inline-flex items-center justify-center w-4 h-4 text-sm font-bold bg-red-light text-red rounded-full">
    {{ unreadCount }}
  </span>
</button>

<mat-menu #notificationMenu="matMenu" class=" bg-white font-qq custom-menu"
  style="border-radius: 1rem; box-shadow: 0px 4px 24px 0px #3030304a">

  <div class="px-8 py-4 border-b-2 ">
    <h3 (click)="$event.stopPropagation()" class="m-0">All Notifications</h3> <!-- Prevent menu close on clicking header -->
  </div>

  <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" class="centered-content">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <div *ngIf="!isLoading" class="px-8 py-2 border-b-2 flex flex-col md:flex-row justify-between font-medium gap-2 bordertb"
    (click)="$event.stopPropagation()">
    <div class="hover:edit-b flex gap-8" style="color: #738195" (click)="$event.stopPropagation()">
      <button mat-button class="link" [class.edit-b]="filterType === 'all'"
        (click)="setFilter('all', $event); $event.stopPropagation()">
        All
      </button>
      <button mat-button class="link" [class.edit-b]="filterType === 'unread'"
        (click)="setFilter('unread', $event); $event.stopPropagation()">
        Unread
      </button>
    </div>
    <div class="edit-b cursor-pointer flex items-center" (click)="markAllAsRead($event); $event.stopPropagation()">Mark all as read</div>
    <div class="edit-b cursor-pointer flex items-center" (click)="markAllDelete($event); $event.stopPropagation()">All delete</div>
   
  </div>

  <div *ngIf="filteredNotifications.length > 0 && !isLoading; else noNotifications" (click)="$event.stopPropagation()">
    <div class="px-8 py-3" *ngFor="let notification of filteredNotifications">
      <div class="flex gap-4 items-center justify-between">
        <div class="flex gap-4 items-center">
          <img *ngIf="notification.senderId?.profile_image; else defaultAvatar"
            [src]="notification.senderId?.profile_image" alt="Profile Image"
            class="h-10 w-10 rounded-full object-cover" />

          <ng-template #defaultAvatar>
            <img src="https://www.example.com/default-avatar.png" alt="Default Avatar"
              class="h-10 w-10 rounded-full object-cover" />
          </ng-template>
          <div>
            <p class="font-semibold cursor-pointer"
              (click)="toggleMessageVisibility(notification, $event); $event.stopPropagation()">
              {{ notification.title }} <span class="px-4 text-sm" style="color: #738195">{{ getTimeAgo(notification.createdAt) }}</span>
            </p>
            <p *ngIf="notification.showMessage || notification.unread === false">
              {{ notification.message }}

            </p>

          </div>
        </div>
        
      </div>
    </div>
  </div>

  <ng-template #noNotifications>
    <div class="px-8 py-5 text-center" style="color: #738195" (click)="$event.stopPropagation()">
      <p (click)="$event.stopPropagation()">No notifications available.</p>
    </div>
  </ng-template>
</mat-menu>