<div class="w-full h-full" fxLayout="row">
    <div class="px-10 py-6">
        <div>
            <h2 class="form-title">Digital Acceptance Form</h2>
        </div>

        <div class="mt-4">
            <p class=" form-subtitle">I consent to electronic service of medicolegal reports in lieu of physical mail by the United States Postal Service. This includes reports for PQMEs (Panel Qualified Medical Evaluations), AMES (Agreed Medical Evaluations), and other reports.</p>
        </div>

        <div class="mt-4">
            <p class=" form-subtitle">The electronic service email address is: {{this.email}}</p>
        </div>

        <div class="mt-4">
            <p class=" form-subtitle">If my email address changes, or I no longer wish to accept electronic service, I will notify OrthoLegal immediately at info&#64;ortholegalgroup.com.</p>
        </div>

        <div class="mt-4">
            <p class="form-subtitle" *ngIf="type === 'Applicant'">Name: {{ fullName }}</p>
            <p class="form-subtitle" *ngIf="type !== 'Applicant'">Name: {{ name }}</p>
          </div>
          

        <div class="mt-4">
            <p class="form-subtitle">Date: {{ currentDate }}</p>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <form (submit)="onSave(form)" #form="ngForm" class="mb-2">
                <h4>Please Add your signature here</h4>

                <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="680"
                    height="100" style="border: 1px solid #ccc; border-radius: 4px; background-color: white;">
                </canvas>

                <p class="error-message text-red-500 mt-2" *ngIf="signatureNeeded">Signature is required</p>

                <div class="buttons flex justify-between">
                    <button mat-button (click)="clearPad()" type="button">
                        <mat-icon>clear</mat-icon> Clear
                    </button>
                </div>

                 <button mat-raised-button matTooltip="Submit" class="primary-button" type="submit"
                    [class.spinner]="isLoading" [disabled]="isLoading">
                    Submit
                </button>
            </form>
        </div>
        <!-- <div @fadeInUp class=" overflow-auto w-1/2 h-[100vh]">
            <div class="p-4" fxLayout="column" fxLayoutGap="10px">
                <form (submit)="onSave(form)" #form="ngForm" class="mb-2">
                    <h4>Please Add your signature here</h4>

                    <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="680"
                        height="100" style="border: 1px solid #ccc; border-radius: 4px; background-color: white;">
                    </canvas>

                    <p class="error-message text-red-500 mt-2" *ngIf="signatureNeeded">Signature is required</p>

                    <div class="buttons flex justify-between">
                        <button mat-button (click)="clearPad()" type="button">
                            <mat-icon>clear</mat-icon> Clear
                        </button>
                    </div>

                    <button mat-raised-button matTooltip="Submit" class="primary-button" type="submit"
                        [class.spinner]="isLoading" [disabled]="isLoading">
                        Submit
                    </button>
                </form>
            </div>
        </div> -->
    </div>
</div>
