import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { AuthService } from "../authServices";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'vex-digital-service-form',
  templateUrl: './digital-service-form.component.html',
  styleUrls: ['./digital-service-form.component.scss']
})
export class DigitalServiceFormComponent implements OnInit, OnDestroy {
  hide = true;
  showPassword = false;
  isLoading = false;
  private authStatusSub!: Subscription;
  signatureNeeded = false;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg = '';
  currentDate: string;
  // Variables for query parameters
  type!: string;
  name!: string;
  encounterId: any;
  applicantDigitalService: any;
  
  // Checkbox for digital service acceptance
  isDigitalServiceAccepted = false;
  email: any;
  fullName: any;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const today = new Date();
    this.currentDate = today.toLocaleDateString(); 
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
      this.name = params['name'];
      this.email = params['email'];
      this.encounterId = params['encounterId'];
      this.fullName = params['full'];
    });

    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(authStatus => {
      this.isLoading = false;
    });
  }

  onSave(form: NgForm) {
    // if (!this.isDigitalServiceAccepted) {
    //   this.applicantDigitalService = "No";
    //   this.submitForm(); 
    //   return;
    // }

    if (form.invalid || this.signaturePad.isEmpty()) {
      this.signatureNeeded = true;
      return;
    }

    this.isLoading = true;
    this.signatureImg = this.signaturePad.toDataURL();
    this.applicantDigitalService = "Accepted"; // Set as Accepted when signature is provided
    this.submitForm();
  }

  submitForm() {
    this.authService.add(this.name, this.type, this.encounterId, this.applicantDigitalService, this.signatureImg).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.toastr.success("Signature submitted successfully!", "Success!", {
          timeOut: 3000,
          closeButton: true,
        });
        this.router.navigate(['/thankyou']);
      },
      error: (error) => {
        this.isLoading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  
  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }


  clearPad() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
