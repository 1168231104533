
  <div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
     matRipple>
  
  <div class="flex flex-col ltr:mr-3 rtl:ml-3" fxHide.xs>
    <div class="body-1 font-medium leading-snug" style="font-weight: 600;">Hi {{ userName | titlecase }}!</div>
    <div class="leading-snug text-xs">{{ roleName }}</div>
  </div>

  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
    <ng-container *ngIf="data?.profile_image; else showIcon">
      <img
      [src]="(data?.profile_image && data.profile_image !== 'null' && data.profile_image !== null && data.profile_image !== '') ? data.profile_image : '/assets/user.png'"
        alt="Profile Image"
        class="h-full w-full object-cover rounded-full"
        (error)="handleImageError($event)"
      />
    </ng-container>
    <ng-template #showIcon>
      <mat-icon style="color: #0044CF;">person</mat-icon>
    </ng-template>
  </div>
  

</div>

