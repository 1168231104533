// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    // FOR PROD

  // apiUrl: "https://portal.ortholegalgroup.com/api/v1/oqme",
  // imageURL: "https://portal.ortholegalgroup.com/api/img/",
  // pdfURL: "https://portal.ortholegalgroup.com/api/pdfimg/",
  // fileURL: "https://portal.ortholegalgroup.com/api/file/",
  // docURL: "https://portal.ortholegalgroup.com/api/doc/",


  // FOR DEVELOPMENT

  apiUrl: "https://dev.ortholegalgroup.com/api/api/v1/oqme",
  imageURL: "https://dev.ortholegalgroup.com/api/api/img/",
  pdfURL: "https://dev.ortholegalgroup.com/api/api/pdfimg/",
  fileURL: "https://dev.ortholegalgroup.com/api/api/file/",
  docURL: "https://dev.ortholegalgroup.com/api/api/doc/",

  // FOR LOCAL
  
  // apiUrl: "http://localhost:8081/api/v1/oqme",
  // imageURL: "http://localhost:8081/api/img/",
  // pdfURL: "http://localhost:8081/api/pdfimg/",
  // fileURL: "http://localhost:8081/api/file/",
  // docURL: "http://localhost:8081/api/doc/",

  firebaseConfig : {
    apiKey: "AIzaSyCHW_k9JlfRLmW5FXXMLW4qfesyxLBlhvc",
    authDomain: "aerial-handbook-429905-e5.firebaseapp.com",
    projectId: "aerial-handbook-429905-e5",
    storageBucket: "aerial-handbook-429905-e5.appspot.com",
    messagingSenderId: "397201401860",
    appId: "1:397201401860:web:27925732c2cb86bf3ced51",
    measurementId: "G-XRYSBPVH46"
    
  },




};
