import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";
import { DemoService } from "../demo.service";

@Component({
  selector: 'vex-body-parts',
  templateUrl: './body-parts.component.html',
  styleUrls: ['./body-parts.component.scss']
})
export class BodyPartsComponent {
  isLoading = false; // Spinner loading state
  bodyParts = {
    leftSide: [],
    rightSide: [],
    bilateralSide: [],
    unspecifiedSide: [],
  };
  selectedParts: string[] = [];
  selectedTab: string = "Left";
  searchControl = new FormControl();
  filteredParts: string[] = [];
  selectedFilter: string = "Orthopedics"; // Default filter category

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BodyPartsComponent>,
    private toastr: ToastrService,
    private service: DemoService
  ) {}

  ngOnInit(): void {
    this.updateFilteredParts();

    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), startWith(""))
      .subscribe((query) => {
        this.updateFilteredParts(query);
      });

    this.fetchBodyParts(this.selectedFilter);
  }

  updateFilteredParts(query: string = ""): void {
    console.log(query)
    // Ensure query is a string and convert it to lowercase
    const lowercaseQuery = query ? query.toLowerCase() : "";
  
    const parts = this.getPartsByTab();
    this.filteredParts = parts
      .filter((part) => part && part.name && part.side && part.side.toLowerCase().includes(lowercaseQuery))
      .map((part) => `${part.name} (${part.side})`);  

  }
  

  fetchBodyParts(filter: string = "") {
    this.isLoading = true; 
    this.service.getAllBodyParts(filter).subscribe(
      (response) => {
        this.bodyParts = response.data;
        this.updateFilteredParts();
        this.isLoading = false; 
      },
      (error) => {
        // this.toastr.error("Failed to load body parts.", "Error");
        this.isLoading = false; 
      }
    );
  }

  getPartsByTab(): any[] {
    switch (this.selectedTab) {
      case "Left":
        return this.bodyParts.leftSide;
      case "Right":
        return this.bodyParts.rightSide;
      case "Bilateral":
        return this.bodyParts.bilateralSide;
      case "Unspecified":
        return this.bodyParts.unspecifiedSide;
      default:
        return [];
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.selectedParts);
  }

  toggleSelection(part: string): void {
    const index = this.selectedParts.indexOf(part);
    if (index === -1) {
      this.selectedParts.push(part);
    } else {
      this.selectedParts.splice(index, 1);
    }
  }

  isSelected(part: string): boolean {
    return this.selectedParts.includes(part);
  }

  selectTab(tab: string): void {
    this.selectedTab = tab;
  
    this.updateFilteredParts(this.selectedTab); 
  }

  applyFilter(filter: string): void {
    this.selectedFilter = filter;
    this.fetchBodyParts(filter);
  }

  // addNewBodyPart(): void {
  //   const dialogRef = this.dialog.open(AddBodyPartComponent);

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.bodyParts[this.selectedTab.toLowerCase() + "Side"].push(result);
  //       this.updateFilteredParts(this.searchControl.value);
  //       this.toastr.success("New body part added.", "Successfully!", {
  //         timeOut: 2000,
  //       });
  //     }
  //   });
  // }

  getPartsForColumn(column: number): string[] {
    const totalParts = this.filteredParts.length;
    const partsPerColumn = Math.ceil(totalParts / 3);
    const start = (column - 1) * partsPerColumn;
    const end = start + partsPerColumn;
    return this.filteredParts.slice(start, end);
  }
}
