<div class="upload-dialog">
  <h2>Add a photo</h2>
  <p class="description">
    OrthoLegal requires you to upload a profile picture before proceeding.
  </p>
  <div
    class="upload-box"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    [class.active]="isDragging"
  >
    <p *ngIf="!selectedFile">Drop image here or <span class="browse" (click)="onBrowseClick()">browse</span></p>
    <p *ngIf="selectedFile">{{ selectedFile.name }}</p>
    <p class="supported">Supports JPEG, JPEG 2000, PNG</p>
    <input type="file"  accept="image/*" #fileInput (change)="onFileSelected($event)" hidden />
  </div>
  <div class="actions">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button
    mat-raised-button
    class="qqmeIcon"
    [disabled]="!selectedFile || isUploading"
    (click)="onSave()"
  >
    <mat-spinner *ngIf="isUploading" diameter="20"></mat-spinner>
    <span *ngIf="!isUploading">Save</span>
  </button>
  </div>
</div>
