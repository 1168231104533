


<div class="dropdown">
  <div class="card overflow-hidden shadow-2xl" >

    <div *ngIf="isLoading" class="flex justify-center items-center h-full w-full mt-6 ">
      <mat-spinner  [diameter]="40"></mat-spinner>
    </div>
   
    <div class="p-4 text-center hover:bg-hover trans-ease-out cursor-pointer relative" matRipple *ngIf="!isLoading">
      <img [src]="(data?.profile_image && data.profile_image !== 'null' && data.profile_image !== null && data.profile_image !== '') ? data.profile_image : '/assets/user.png'" class="avatar h-24 w-24 mx-auto">

      <div class="body-2 text-secondary">
        <span>{{data?.roleName}}</span>
      </div>

      <h2 class="title mb-1 mt-3">{{data?.name}}</h2>

      <div class="body-2 text-secondary">
        <ic-icon [icon]="icMail" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
        <span>{{data?.email}}</span>
      </div>

      <div *ngIf="data?.phone && data.phone !== 'null' && data.phone !== null" class="body-2 text-secondary">
        <ic-icon [icon]="icPhone" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
        <span>{{ data?.phone }}</span>
      </div>
      
      <div *ngIf="data?.officePhone && data.officePhone !== 'null' && data.officePhone !== null" class="body-2 text-secondary">
        <ic-icon [icon]="icPhone" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
        <span>{{ data?.officePhone }}</span>
      </div>
      



      <button class="absolute top-2 right-2" mat-icon-button type="button">
        <mat-icon></mat-icon>
        <mat-icon></mat-icon>
      </button>
    </div>

    <div class="bg-app-bar p-2" fxLayout="row" fxLayoutAlign="space-around start">
      <button [matMenuTriggerFor]="settingsMenu" mat-icon-button matTooltip="Settings" 
        type="button">
        <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
      </button>

      <button (click)="close()" [routerLink]="'/admin'" mat-icon-button matTooltip="Profile Manage" type="button">
        <mat-icon class="notifications-header-icon">manage_accounts</mat-icon>
      </button>


      <a *ngIf="userIsAuthenticated" (click)="onLogout()" style="color:#0044CF !important" mat-button>LOGOUT</a>

    </div>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="close()" routerLink="/admin/change-password" mat-menu-item>
    <mat-icon [icIcon]="icLock"></mat-icon>
    <span>Change Password</span>
  </button>
</mat-menu>