import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VexRoutes } from "src/@vex/interfaces/vex-route.interface";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { AuthGuard } from "./pages/auth/auth.guard";
import { DigitalServiceFormComponent } from "./pages/auth/digital-service-form/digital-service-form.component";
import { ThankyouComponent } from "./pages/auth/thankyou/thankyou.component";
import { DemoComponent } from "./demo/demo.component";
import { ThankComponent } from "./demo/thank/thank.component";
const childrenRoutes: VexRoutes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/auth/profile-tab/profile-tab.module").then(
        (m) => m.ProfileTabModule
      ),
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("./pages/calendar/calendar.module").then((m) => m.CalendarModule),
  },
  {
    path: "database",
    loadChildren: () =>
      import("./pages/user-management/user-management.module").then((m) => m.UserManagementModule),
  },
  { path: 'queue', loadChildren: () => import('./pages/queue/queue.module').then(m => m.QueueModule) },
  {
    path: "reports",
    loadChildren: () =>
      import("./pages/reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: "billing",
    loadChildren: () =>
      import("./pages/bill/bill.module").then((m) => m.BillModule),
  },
];

const routes: Routes = [
  {
    path: 'digitalService_acceptance_form',
    component: DigitalServiceFormComponent
  },
  {
    path: 'demographics_form',
    component: DemoComponent
  },
  {
    path: 'thankyou',
    component: ThankyouComponent
  },
  {
    path: 'thank_you_for_request_form',
    component: ThankComponent
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "",
    component: CustomLayoutComponent,
    children: childrenRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/auth/check-email/check-email.module").then(
        (m) => m.CheckEmailModule
      ),
  },
  {
    path: "reset-password/:token",
    loadChildren: () =>
      import("./pages/auth/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "error-500",
    loadChildren: () =>
      import("./pages/505/505.module").then((m) => m.Error505Module),
  },
  


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
      useHash: false,
      // enableTracing: true
      
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
