import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../authServices';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vex-profile-image-dialog',
  templateUrl: './profile-image-dialog.component.html',
  styleUrls: ['./profile-image-dialog.component.scss']
})
export class ProfileImageDialogComponent {
  isDragging = false;
  @ViewChild('fileInput') fileInput: any;
  selectedFile: File | null = null;
  isUploading = false;
  constructor(private service: AuthService,private toastr: ToastrService, private dialogRef: MatDialogRef<ProfileImageDialogComponent> ) {}
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      console.log('Dropped file:', this.selectedFile);
    }
  }
  onBrowseClick(): void {
    // Trigger the file input click event
    this.fileInput.nativeElement.click();
  }
  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
    }
  }

  onCancel(): void {
    this.selectedFile = null;
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.selectedFile) {
      this.isUploading = true;

      this.service.updateProfileImage(this.selectedFile).subscribe(
        (response) => {
          this.isUploading = false;  
          this.toastr.success('Done!', 'Profile pic updated successfully.', {
            timeOut: 2500,
            closeButton: true,
          });
          this.dialogRef.close();
        },
        (error) => {
          this.isUploading = false;  
          // this.toastr.error('Error', 'Failed to update profile pic.', {
          //   timeOut: 2500,
          //   closeButton: true,
          // });
        }
      );
    }
  }
}
