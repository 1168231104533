import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from "@angular/core";
import { ConfigService } from "../@vex/services/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";
import icLayers from "@iconify/icons-ic/twotone-layers";
import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { Style, StyleService } from "../@vex/services/style.service";
import { ConfigName } from "../@vex/interfaces/config-name.model";
// import icLayers from "@iconify/icons-ic/twotone-layers";
import icUser from "@iconify/icons-ic/baseline-people-alt";
import { AuthService } from "./pages/auth/authServices";
import { FcmService } from "./fcm.service";
@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "vex";
  userIsAuthenticated = false;
  // currentMessage = this.fcmService.currentMessage;
  constructor(
    private fcmService: FcmService,
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private authService: AuthService
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.route.queryParamMap
      .pipe(
        map(
          (queryParamMap) =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? "rtl" : "ltr";
        this.configService.updateConfig({
          rtl: isRtl,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    this.navigationService.items = [
      {
        type: "subheading",
        label: "",
        children: [
          {
            type: "link",
            label: "Home",
            route: "/dashboard",
            imgIcon: "cottage",
          },
          {
            type: "link",
            label: "Calendar",
            route: "/calendar",
            imgIcon: "calendar_month",
          },
          {
            type: "link",
            label: "Queue",
            route: "/queue",
            imgIcon: "list_alt",
          },
          {
            type: "link",
            label: "Task List",
            route: "/reports",
            imgIcon: "draft",
          },
          {
            type: "link",
            label: "Billing",
            route: "/billing",
            imgIcon: "paid",
          },
          // {
          //   type: "dropdown",
          //   label: "User Management",
          //   icon: icUser,
          //   imgIcon: "face",
          //   children: [
          //     {
          //       type: "link",
          //       label: "Users",
          //       route: "/administrator",
          //     },
          //     {
          //       type: "link",
          //       label: "Roles And Permissions",
          //       route: "/administrator/all-roles",
          //     },
          //   ],
          // },
          {
            type: "dropdown",
            label: "Database",
            icon: icUser,
            imgIcon: "database",
            children: [
              {
                type: "link",
                label: "Roles & Users",
                route: "/database",
              },
              {
                type: "link",
                label: "Clinic Locations",
                route: "/database/clinic_locations",
              },
              {
                type: "link",
                label: "Applicants",
                route: "/database/applicant",
              },
              // {
              //   type: "link",
              //   label: "Employers",
              //   route: "/database/employer",
              // },
              {
                type: "link",
                label: "Attorneys",
                route: "/database/attorney",
              },
              {
                type: "link",
                label: "Insurance",
                route: "/database/insurance_company",
              },
              {
                type: "link",
                label: "Interpreters",
                route: "/database/interpreter",
              },
              {
                type: "link",
                label: "Body parts",
                route: "/database/body_parts",
              },
              {
                type: "link",
                label: "Business setup",
                route: "/database/business_setup",
              },
              {
                type: "link",
                label: "Due dates",
                route: "/database/dueDate",
              },
              {
                type: "link",
                label: "Notification settings",
                route: "/database/notification_setting",
              },
              {
                type: "link",
                label: "ML code & Modifiers",
                route: "/database/ml_modifiers",
              },
            ],
          },
        ],
      },
    ];
  }

  ngOnInit() {
    this.authService.autoAuthUser();
    // this.requestPermission()
  }

  // requestPermission() {
  //   this.fcmService.requestPermission();
  // }

  ngOnChanges(): void {}
}
