<div *ngIf="!isRecaptchaVerified">
  <div class="pb-4 px-6 py-4" style="background-color: #f7fcff" fxLayout="column" fxLayoutAlign="center start">
    <re-captcha (resolved)="onRecaptchaResolved($event)" siteKey="6LdmgpIqAAAAAOA0iZF5FC9cz1FbNYyr8-3sN3EY">
    </re-captcha>
  </div>
</div>

<div *ngIf="isRecaptchaVerified">
  <!-- <div> -->
  <div class="px-6 h-16 border-b sticky left-0" style="background-color: #f7fcff" fxLayout="row"
    fxLayoutAlign="start center">
    <img src="assets/Frame 3648.png" alt="OrthoLegal Logo" width="50" class="my-0" />

    <span fxFlex></span>

    <span class="text-sm"><mat-icon class="t2 px-2">phone</mat-icon>213.947.0000</span>
  </div>
  <div class="pb-4 px-6 py-4" style="background-color: #f7fcff" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="title m-0 qqmeTxt_2">Appointment Request Form</h2>
  </div>

  <mat-spinner [diameter]="40" class="qqmeBtn" *ngIf="isLoading"></mat-spinner>

  <div @fadeInUp [@stagger]="true" class="p-gutter" vexContainer
    style="background-color: #e9eef7; height: 80vh; overflow-y: auto" *ngIf="!isLoading">
    <div class="card" style="min-height: 100%; height: auto">
      <div class="px-20 py-6" fxLayout="column">
        <form [formGroup]="form" (submit)="onSave()" #formDirective="ngForm">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-2">
            <h2 class="title m-0 qqmeTxt_2">Requesting party details</h2>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label> Name</mat-label>
              <input matInput type="text" formControlName="reqFirstName" (input)="capitalizeFirstLetter('reqFirstName')"
                (paste)="capitalizeFirstLetter('reqFirstName')" required />
              <mat-error *ngIf="form.get('reqFirstName').hasError('required')">
                Name is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label> Middle Name</mat-label>
              <input matInput type="text" formControlName="reqMiddleName"
                (input)="capitalizeFirstLetter('reqMiddleName')" (paste)="capitalizeFirstLetter('reqMiddleName')" />
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label> Last Name</mat-label>
              <input matInput type="text" formControlName="reqLastName" (input)="capitalizeFirstLetter('reqLastName')"
                (paste)="capitalizeFirstLetter('reqLastName')" required />
              <mat-error *ngIf="form.get('reqLastName').hasError('required')">
                Last Name is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="reqEmail" />
              <!-- <mat-error *ngIf="
                  form.get('reqEmail').hasError('required')
                ">
                Email is required and cannot be empty or whitespace.
              </mat-error> -->
              <mat-error *ngIf="form.get('reqEmail').hasError('email')">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Requesting Party</mat-label>
              <mat-select formControlName="reqParty" required>
                <mat-option *ngFor="let party of requestingParties" [value]="party">
                  {{ party }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('reqParty').hasError('required')">
                Is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-10">
            <h2 class="title m-0 qqmeTxt_2">Applicant Details</h2>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Applicant Name</mat-label>
              <input matInput type="text" formControlName="applicantName"
                (input)="capitalizeFirstLetter('applicantName')" (paste)="capitalizeFirstLetter('applicantName')"
                required />
              <mat-error *ngIf="
                  form.get('applicantName').hasError('required') ||
                  form.get('applicantName').hasError('whitespace')
                ">
                Applicant Name is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Applicant Middle Name</mat-label>
              <input matInput type="text" formControlName="applicantMiddleName"
                (input)="capitalizeFirstLetter('applicantMiddleName')"
                (paste)="capitalizeFirstLetter('applicantMiddleName')" />
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Applicant Last Name</mat-label>
              <input matInput type="text" formControlName="applicantLastName"
                (input)="capitalizeFirstLetter('applicantLastName')"
                (paste)="capitalizeFirstLetter('applicantLastName')" required />
              <mat-error *ngIf="
                  form.get('applicantLastName').hasError('required') ||
                  form.get('applicantLastName').hasError('whitespace')
                ">
                Applicant Last Name is required and cannot be empty or
                whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="email" />

              <mat-error *ngIf="form.get('email').hasError('email')">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Phone</mat-label>
              <input matInput type="text" formControlName="phone" required />
              <mat-error *ngIf="form.get('phone').hasError('required')">
                Phone is required and cannot be empty or whitespace.
              </mat-error>
              <mat-error *ngIf="
                  form.get('phone').hasError('phone') &&
                  (form.get('phone').touched || form.get('phone').dirty)
                ">
                Phone must contain only numbers.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Alternate Phone</mat-label>
              <input matInput type="text" formControlName="altPhone" />
              <mat-error *ngIf="form.get('altPhone')?.hasError('phone')">
                Alternate phone number should contain only digits.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="dob" required />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="form.get('dob').hasError('required')">
                DOB is required.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="gender" required>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('gender').hasError('required')">
                Gender is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Address</mat-label>
              <input matInput type="text" formControlName="address" required />
              <mat-error *ngIf="
                  form.get('address').hasError('required') ||
                  form.get('address').hasError('whitespace')
                ">
                Address is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="city" (input)="capitalizeFirstLetter('city')"
                (paste)="capitalizeFirstLetter('city')" required />

              <mat-error *ngIf="
                  form.get('city').hasError('required') ||
                  form.get('city').hasError('whitespace')
                ">
                City is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" required>
                <mat-option *ngFor="let state of states" [value]="state">{{
                  state
                  }}</mat-option>
              </mat-select>
              <mat-error *ngIf="
                  form.get('state').hasError('required') ||
                  form.get('state').hasError('whitespace')
                ">
                State is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>ZIP</mat-label>
              <input matInput type="text" formControlName="zip" required maxlength="5" />

              <mat-error *ngIf="
                  form.get('zip')?.hasError('required') ||
                  form.get('zip').hasError('whitespace')
                ">
                ZIP is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Job Title</mat-label>
              <input matInput type="text" formControlName="jobtitle" (input)="capitalizeFirstLetter('jobtitle')"
                (paste)="capitalizeFirstLetter('jobtitle')" required />
              <mat-error *ngIf="
                  form.get('jobtitle')?.hasError('required') ||
                  form.get('jobtitle').hasError('whitespace')
                ">
                ZIP is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>SSN</mat-label>
              <input matInput type="text" formControlName="ssn" />
              <mat-hint align="start" style="color: #084a91; font-weight: 500">Optional</mat-hint>
              <!-- <mat-error *ngIf="form.get('ssn')?.hasError('ssn')">
                Invalid SSN format. Please enter a valid SSN in the digit format XXX-XX-XXXX.
              </mat-error> -->
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Applicant Mode of Transmission</mat-label>
              <mat-select formControlName="modeOfTransmission">
                <mat-option value="USPS">USPS</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Digital service confirmation</mat-label>
              <mat-select formControlName="applicantDigitalService">
                <mat-option *ngIf="
                    form.get('applicantDigitalService')?.value !== 'Accepted' &&
                    form.get('applicantDigitalService')?.value !== 'No'
                  " value="Email Sent">Send an acceptance link</mat-option>
                <mat-option *ngIf="
                    form.get('applicantDigitalService')?.value !== 'Accepted' &&
                    form.get('applicantDigitalService')?.value !== 'No' &&
                    form.get('applicantDigitalService')?.value !== 'Email Sent'
                  " value="Not sent">Not sent an acceptance link</mat-option>

                <mat-option *ngIf="
                    form.get('applicantDigitalService')?.value !== 'No' &&
                    form.get('applicantDigitalService')?.value !==
                      'Email Sent' &&
                    form.get('applicantDigitalService')?.value !== 'Not sent'
                  " value="Accepted" disabled>Accepted</mat-option>
                <mat-option *ngIf="
                    form.get('applicantDigitalService')?.value !== 'Accepted' &&
                    form.get('applicantDigitalService')?.value !==
                      'Email Sent' &&
                    form.get('applicantDigitalService')?.value !== 'Not sent'
                  " value="No" disabled>Not Accepted</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div formArrayName="injuryDetails" class="mt-6">
            <div *ngFor="let detail of injuryDetails.controls; let i = index">
              <div [formGroupName]="i">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                  <h2 class="title m-0 qqmeTxt_2">
                    Injury details {{ i + 1 }}
                  </h2>
                </div>
                <!-- <mat-hint align="start" style="color: #084a91; font-weight: 300">* All injury details are mandatory.</mat-hint> -->
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
                  <div fxFlex="auto">
                    <mat-radio-group formControlName="injuryType" (change)="onInjuryTypeChange(i)">
                      <mat-radio-button value="specific" class="px-4">Specific</mat-radio-button>
                      <mat-radio-button value="cumulative">Cumulative</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <ng-container *ngIf="detail.get('injuryType')?.value === 'specific'">
                  <!-- Specific: Single Date -->
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
                    <mat-form-field appearance="outline" fxFlex="auto">
                      <mat-label>MM/DD/YYYY</mat-label>
                      <input matInput [matDatepicker]="picker" formControlName="specificDate" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error *ngIf="detail.get('specificDate')?.hasError('required')">
                        Specific date is required.
                      </mat-error>
                    </mat-form-field>
                    <div fxFlex="auto"></div>
                    <div fxFlex="auto"></div>
                  </div>
                </ng-container>

                <ng-container *ngIf="detail.get('injuryType')?.value === 'cumulative'">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
                    <mat-form-field appearance="outline" fxFlex="auto">
                      <mat-label>Date Range</mat-label>
                      <mat-date-range-input [formGroup]="detail" [rangePicker]="dateRangePicker">
                        <input matStartDate placeholder="Start date" formControlName="start" />
                        <input matEndDate placeholder="End date" formControlName="end" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>

                      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    </mat-form-field>
                    <div fxFlex="auto"></div>
                    <div fxFlex="auto"></div>
                  </div>
                </ng-container>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
                  <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>Injury Body Part</mat-label>
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="
                          let part of detail.get('injuryBodyPart').value || []
                        " [removable]="true" (removed)="removeChip(i, part)">
                        {{ part }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                    <input matInput placeholder="Select Injury Body Parts" [value]="
                        detail.get('injuryBodyPart').value
                          ? detail.get('injuryBodyPart').value.join(', ')
                          : ''
                      " [class.hidden]="
                        detail.get('injuryBodyPart').value &&
                        detail.get('injuryBodyPart').value.length > 0
                      " (click)="openBodyPartsDialog(i)" readonly />
                    <mat-hint *ngIf="
                        !detail.get('injuryBodyPart').value ||
                        detail.get('injuryBodyPart').value.length === 0
                      ">
                      Body part selection is required.
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
                  <ng-container *ngIf="
                      !injuryDetails.at(i).get('isCustomWCAB').value;
                      else customWCABInput
                    ">
                    <mat-form-field appearance="outline" fxFlex="auto">
                      <mat-label>WCAB</mat-label>
                      <mat-select formControlName="WCAB" (selectionChange)="onWCABChange(i, $event.value)">
                        <mat-option [value]="'None Provided'">None Provided</mat-option>
                        <mat-option [value]="''">Enter Custom Text</mat-option>
                      </mat-select>
                      <mat-hint align="start">If no WCAB case number is provided, select ‘None
                        Provided’.</mat-hint>
                    </mat-form-field>
                  </ng-container>

                  <ng-template #customWCABInput>
                    <mat-form-field appearance="outline" fxFlex="auto">
                      <mat-label>WCAB</mat-label>
                      <input matInput formControlName="WCAB" />
                    </mat-form-field>
                  </ng-template>

                  <ng-container *ngIf="
                      !injuryDetails.at(i).get('isCustomClaimNumber').value;
                      else customClaimNumberInput
                    ">
                    <mat-form-field appearance="outline" fxFlex="auto">
                      <mat-label>Claim Number</mat-label>
                      <mat-select formControlName="claimNumber"
                        (selectionChange)="onClaimNumberChange(i, $event.value)">
                        <mat-option [value]="'None Provided'">None Provided</mat-option>
                        <mat-option [value]="''">Enter Custom Text</mat-option>
                      </mat-select>
                      <mat-hint align="start">If no claim number is provided, select ‘None
                        Provided’.</mat-hint>
                    </mat-form-field>
                  </ng-container>

                  <ng-template #customClaimNumberInput>
                    <mat-form-field appearance="outline" fxFlex="auto">
                      <mat-label> Claim Number</mat-label>
                      <input matInput formControlName="claimNumber" />
                    </mat-form-field>
                  </ng-template>

                  <button mat-icon-button type="button" matTooltip="Remove" class="qqmeIcon-2"
                    (click)="removeInjuryDetail(i)">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <button mat-stroked-button type="button" matTooltip="Add Injury" class="qqmeIcon-2"
              (click)="addInjuryDetail()">
              + Add Injury
            </button>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-10">
            <h2 class="title m-0 qqmeTxt_2">Encounter Details</h2>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Physician</mat-label>
              <mat-select formControlName="physician" (selectionChange)="onPhysicianChange($event.value)">
                <mat-option *ngFor="let physician of physicians" [value]="physician._id">
                  {{ physician.f_name | titlecase }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('physician')?.hasError('required')">
                Physician is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Medical Evaluation Type</mat-label>
              <mat-select formControlName="medicalEvaluationType">
                <mat-option value="Qualified Medical Evaluation">Qualified Medical Evaluation</mat-option>
                <mat-option value="Agreed Medical Evaluation">Agreed Medical Evaluation</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('medicalEvaluationType')?.hasError('required')">
                Medical Evaluation Type is required.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto" *ngIf="
                form.get('medicalEvaluationType')?.value ===
                'Qualified Medical Evaluation'
              ">
              <mat-label>Encounter Type</mat-label>
              <mat-select formControlName="encounterType">
                <mat-option value="PQME">PQME</mat-option>
                <mat-option value="PQMER">PQMER</mat-option>
                <!-- <mat-option value="PQMES">PQMES</mat-option> -->
                <mat-option value="PQMED">PQMED</mat-option>
                <!-- <mat-option value="PQMED">PQMED</mat-option>
                <mat-option value="SIBTF">SIBTF</mat-option> -->
              </mat-select>
              <mat-error *ngIf="form.get('encounterType')?.hasError('required')">
                is required.
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="
                form.get('medicalEvaluationType')?.value ===
                'Agreed Medical Evaluation'
              " appearance="outline" fxFlex="auto">
              <mat-label>Encounter Type</mat-label>
              <mat-select formControlName="encounterType">
                <mat-option value="AME">AME</mat-option>
                <mat-option value="AMER">AMER</mat-option>
                <mat-option value="AMES">AMES</mat-option>
                <mat-option value="AMED">AMED</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('encounterType')?.hasError('required')">
                is required.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Evaluation Type</mat-label>
              <mat-select formControlName="evaluationType">
                <mat-option value="Basic">Basic</mat-option>
                <mat-option value="Comprehensive">Comprehensive</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto" *ngIf="
                form.get('medicalEvaluationType')?.value !==
                'Agreed Medical Evaluation'
              ">
              <mat-label>Panel Number</mat-label>
              <input matInput formControlName="panelNumber" />
              <!-- <mat-error *ngIf="form.get('panelNumber').hasError('required')">
                panel number is required.
              </mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Date Range</mat-label>
              <mat-date-range-input [rangePicker]="dateRangePicker1">
                <input matStartDate placeholder="Start date" formControlName="reqDateRange_start" />
                <input matEndDate placeholder="End date" formControlName="reqDateRange_end" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="dateRangePicker1"></mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker1></mat-date-range-picker>

              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" *ngIf="
              form.get('encounterType')?.value === 'PQMED' ||
              form.get('encounterType')?.value === 'AMED'
            ">
            <mat-checkbox formControlName="online"> Online </mat-checkbox>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto" *ngIf="form.get('online')?.value === true">
              <mat-label>Meeting Link</mat-label>
              <input matInput formControlName="meetingLink" />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Examination Address</mat-label>
              <mat-select formControlName="examinationAddress" required>
                <mat-option *ngFor="let clinic of clinics" [value]="clinic._id">
                  {{ clinic.clinic_address | titlecase }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  form.get('examinationAddress')?.hasError('required') ||
                  form.get('examinationAddress').hasError('whitespace')
                ">
                Examination address is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="examinationCity"
                (input)="capitalizeFirstLetter('examinationCity')" (paste)="capitalizeFirstLetter('examinationCity')"
                required />

              <mat-error *ngIf="
                  form.get('examinationCity').hasError('required') ||
                  form.get('examinationCity').hasError('whitespace')
                ">
                City is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>State</mat-label>
              <mat-select formControlName="examinationState" required>
                <mat-option *ngFor="let state of states" [value]="state">{{
                  state
                  }}</mat-option>
              </mat-select>
              <mat-error *ngIf="
                  form.get('examinationState').hasError('required') ||
                  form.get('examinationState').hasError('whitespace')
                ">
                State is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>ZIP</mat-label>
              <input matInput type="text" formControlName="examinationZip" maxlength="5" required />

              <mat-error *ngIf="
                  form.get('examinationZip').hasError('required') ||
                  form.get('examinationZip').hasError('whitespace')
                ">
                ZIP is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <h2 class="title m-0 qqmeTxt_2">Previous encounter details</h2>
          </div>

          <div formArrayName="previousEncounter">
            <div *ngFor="let detail of previousEncounter.controls; let i = index">
              <div [formGroupName]="i" class="mt-3">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
                  <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>Previous Encounter date</mat-label>
                    <input matInput [matDatepicker]="previousDate" formControlName="previousDate" />
                    <mat-datepicker-toggle matSuffix [for]="previousDate"></mat-datepicker-toggle>
                    <mat-datepicker #previousDate></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label> Previous evaluation type</mat-label>
                    <input matInput type="text" formControlName="evaluationType" />
                  </mat-form-field>

                  <button mat-icon-button fxFlex="auto" type="button" matTooltip="Remove" class="qqmeIcon-2"
                    (click)="removePreviousDetail(i)">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <button mat-stroked-button type="button" matTooltip="Add Previous Encounter" class="qqmeIcon-2"
              (click)="addPreviousDetail()">
              + Add Previous Encounter
            </button>
          </div>

          <div *ngIf="
              form.get('encounterType')?.value !== 'PQMED' &&
              form.get('encounterType')?.value !== 'AMED'
            ">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-6">
              <mat-form-field appearance="outline" fxFlex="auto" style="display: none">
                <input matInput formControlName="strikePanel" readonly placeholder="Choose files to upload" />
              </mat-form-field>

              <button mat-button type="button" (click)="fileInputStrikePanel.click()"
                class="upload-button upload-button_2">
                <span>Upload strike panel</span>
                <mat-icon>cloud_upload</mat-icon>
              </button>
              <input type="file" #fileInputStrikePanel (change)="onFileSelected($event, 'strikePanel')"
                style="display: none" multiple />
            </div>

            <div *ngFor="let file of selectedStrikePanelFiles; let i = index" fxLayout="row"
              fxLayoutAlign="start center" fxLayoutGap="16px">
              <mat-icon>insert_drive_file</mat-icon>
              <span>{{ file.name }}</span>
              <button mat-icon-button color="warn" (click)="removeFile(i, 'strikePanel')">
                <mat-icon>clear</mat-icon>
              </button>
            </div>

            <div *ngFor="let file of existingStrikePanelFiles" fxLayout="row" fxLayoutAlign="start center"
              fxLayoutGap="16px">
              <mat-icon>insert_drive_file</mat-icon>
              <span>{{ file.name }}</span>
              <a [href]="viewFile(file.id)" target="_blank"
                class="flex items-center space-x-2 text-blue-600 hover:underline">
                <mat-icon class="qqmeIcon">visibility</mat-icon>
              </a>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-6">
              <mat-form-field appearance="outline" fxFlex="auto" style="display: none">
                <input matInput formControlName="attorneyDemo" readonly placeholder="Choose files to upload" />
              </mat-form-field>

              <button mat-button type="button" (click)="fileInputAttorneyDemos.click()"
                class="upload-button upload-button_2">
                <span>Upload attorney demos</span>
                <mat-icon>cloud_upload</mat-icon>
              </button>
              <input type="file" #fileInputAttorneyDemos (change)="onFileSelected($event, 'attorneyDemo')"
                style="display: none" multiple />
            </div>

            <div *ngFor="let file of selectedAttorneyDemoFiles; let i = index" fxLayout="row"
              fxLayoutAlign="start center" fxLayoutGap="16px">
              <mat-icon>insert_drive_file</mat-icon>
              <span>{{ file.name }}</span>
              <button mat-icon-button color="warn" (click)="removeFile(i, 'attorneyDemo')">
                <mat-icon>clear</mat-icon>
              </button>
            </div>

            <div *ngFor="let file of existingAttorneyDemoFiles" fxLayout="row" fxLayoutAlign="start center"
              fxLayoutGap="16px">
              <mat-icon>insert_drive_file</mat-icon>
              <span>{{ file.name }}</span>
              <a [href]="viewFile(file.id)" target="_blank"
                class="flex items-center space-x-2 text-blue-600 hover:underline">
                <mat-icon class="qqmeIcon">visibility</mat-icon>
              </a>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-10">
            <h2 class="title m-0 qqmeTxt_2">Interpreter details</h2>
            <mat-checkbox formControlName="interpreter">
              Interpreter required
            </mat-checkbox>
          </div>

          <div *ngIf="form.get('interpreter').value" class="mt-4">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Language</mat-label>
                <mat-select formControlName="language">
                  <mat-option *ngFor="let language of languages" [value]="language">
                    {{ language }}
                  </mat-option>
                </mat-select>
                <mat-hint align="start" style="color: #084a91; font-weight: 500">Optional</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Vendor Name</mat-label>
                <input matInput type="text" formControlName="vendor" (input)="capitalizeFirstLetter('vendor')"
                  (paste)="capitalizeFirstLetter('vendor')" />
                <mat-hint align="start" style="color: #084a91; font-weight: 500">Optional</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Vendor Phone</mat-label>
                <input matInput type="text" formControlName="vendorPhone" />
                <mat-hint align="start" style="color: #084a91; font-weight: 500">Optional</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-10">
            <h2 class="title m-0 qqmeTxt_2">Applicant attorney info</h2>

            <mat-checkbox formControlName="unrepresented"
              *ngIf="form.get('encounterType')?.value !== 'SIBTF'">Unrepresented</mat-checkbox>
          </div>

          <div *ngIf="!form.get('unrepresented').value">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-3">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Firm</mat-label>
                <input matInput type="text" formControlName="firm" (input)="capitalizeFirstLetter('firm')"
                  (paste)="capitalizeFirstLetter('firm')" />
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="applicantAttorneyname"
                  (input)="capitalizeFirstLetter('applicantAttorneyname')"
                  (paste)="capitalizeFirstLetter('applicantAttorneyname')" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Address</mat-label>
                <input matInput type="text" formControlName="applicantAttorneyaddress" />
              </mat-form-field>

              <!-- <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Mode of Transmission</mat-label>
                <mat-select formControlName="applicantAttorneymodeOfTransmission">
                  <mat-option value="USPS">USPS</mat-option>
                </mat-select>
              </mat-form-field> -->
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>City</mat-label>
                <input matInput type="text" formControlName="applicantAttorneyCity" />
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>State</mat-label>
                <mat-select formControlName="applicantAttorneyState">
                  <mat-option *ngFor="let state of states" [value]="state">{{
                    state
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>ZIP</mat-label>
                <input matInput type="text" formControlName="applicantAttorneyZip" maxlength="5" />
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Telephone</mat-label>
                <input matInput type="text" formControlName="applicantAttorneyTelePhone" />
                <!-- <mat-error *ngIf="
                form.get('applicantAttorneyTelePhone').hasError('phone') &&
                (form.get('applicantAttorneyTelePhone').touched ||
                  form.get('applicantAttorneyTelePhone').dirty)
              ">
                  Phone must contain only numbers.
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Fax</mat-label>
                <input matInput type="text" formControlName="applicantAttorneyFax" />
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="applicantAttorneyEmail" />
                <!-- <mat-error *ngIf="
                form.get('applicantAttorneyEmail').hasError('email') &&
                (form.get('applicantAttorneyEmail').touched ||
                  form.get('applicantAttorneyEmail').dirty)
              ">
                  Please enter a valid email address.
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Assistant Email</mat-label>
                <input matInput type="email" formControlName="applicantAttorneyAssitantEmail" />
                <!-- <mat-error *ngIf="
                form.get('applicantAttorneyAssitantEmail').hasError('email') &&
                (form.get('applicantAttorneyAssitantEmail').touched ||
                  form.get('applicantAttorneyAssitantEmail').dirty)
              ">
                  Please enter a valid email address.
                </mat-error> -->
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
              <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Digital service confirmation</mat-label>

                <mat-select formControlName="applicantAttorneyDigitalService">
                  <mat-option *ngIf="
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Accepted' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'No'
                    " value="Email Sent">Send an acceptance link</mat-option>
                  <mat-option *ngIf="
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Accepted' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'No' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Email Sent'
                    " value="Not sent">Not sent an acceptance link</mat-option>

                  <mat-option *ngIf="
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'No' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Email Sent' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Not sent'
                    " value="Accepted" disabled>Accepted</mat-option>
                  <mat-option *ngIf="
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Accepted' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Email Sent' &&
                      form.get('applicantAttorneyDigitalService')?.value !==
                        'Not sent'
                    " value="No" disabled>Not Accepted</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
            <h2 class="title m-0 qqmeTxt_2">Defence attorney info</h2>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-3">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Firm</mat-label>
              <input matInput type="text" formControlName="defenceFirm"
              (input)="capitalizeFirstLetter('defenceFirm')"
              (paste)="capitalizeFirstLetter('defenceFirm')"
              required />
              <mat-error *ngIf="
                  form.get('defenceFirm').hasError('required') ||
                  form.get('defenceFirm').hasError('whitespace')
                ">
                Firm is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="defenceName" 
              (input)="capitalizeFirstLetter('defenceName')"
              (paste)="capitalizeFirstLetter('defenceName')"
              
              required />
              <mat-error *ngIf="
                  form.get('defenceName').hasError('required') ||
                  form.get('defenceName').hasError('whitespace')
                ">
                Name is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Address</mat-label>
              <input matInput type="text" formControlName="defenceAddress" required />
              <mat-error *ngIf="
                  form.get('defenceAddress').hasError('required') ||
                  form.get('defenceAddress').hasError('whitespace')
                ">
                Address is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Mode of Transmission</mat-label>
              <mat-select formControlName="defencemodeOfTransmission">
                <mat-option value="USPS">USPS</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="defenceCity" required />
              <mat-error *ngIf="
                  form.get('defenceCity').hasError('required') ||
                  form.get('defenceCity').hasError('whitespace')
                ">
                City is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>State</mat-label>
              <mat-select formControlName="defenceState" required>
                <mat-option *ngFor="let state of states" [value]="state">{{
                  state
                  }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('defenceState').hasError('required')">
                State is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Defense Zip</mat-label>
              <input matInput type="text" formControlName="defenceZip" maxlength="5" required />
              <mat-error *ngIf="
                  form.get('defenceZip').hasError('required') ||
                  form.get('defenceZip').hasError('whitespace')
                ">
                ZIP is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Phone</mat-label>
              <input matInput type="text" formControlName="defencePhone" required />
              <mat-error *ngIf="form.get('defencePhone').hasError('required')">
                Phone is required and cannot be empty or whitespace.
              </mat-error>

              <mat-error *ngIf="
                  form.get('defencePhone').hasError('phone') &&
                  (form.get('defencePhone').touched ||
                    form.get('defencePhone').dirty)
                ">
                Phone must contain only numbers.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Fax</mat-label>
              <input matInput type="text" formControlName="defenceFax" />
              <mat-hint align="start" style="color: #084a91; font-weight: 500">Optional</mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="defenceEmail" />
              <!-- <mat-error *ngIf="
                  form.get('defenceEmail').hasError('required')
                ">
                Defence email is required.
              </mat-error> -->
              <!-- <mat-error *ngIf="form.get('defenceEmail').hasError('email')">
                Please enter a valid email address.
              </mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Assistant Email</mat-label>
              <input matInput type="email" formControlName="defenceAssistantEmail" />
              <!-- <mat-error *ngIf="
                  form.get('defenceAssistantEmail').hasError('required') 
                ">
                Defence assistant email is required.
              </mat-error> -->
              <!-- <mat-error *ngIf="form.get('defenceAssistantEmail').hasError('email')">
                Please enter a valid email address.
              </mat-error> -->
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Digital service confirmation</mat-label>
              <mat-select formControlName="defenceDigitalService">
                <mat-option *ngIf="
                    form.get('defenceDigitalService')?.value !== 'Accepted' &&
                    form.get('defenceDigitalService')?.value !== 'No'
                  " value="Email Sent">Send an acceptance link</mat-option>
                <mat-option *ngIf="
                    form.get('defenceDigitalService')?.value !== 'Accepted' &&
                    form.get('defenceDigitalService')?.value !== 'No' &&
                    form.get('defenceDigitalService')?.value !== 'Email Sent'
                  " value="Not sent">Not sent an acceptance link</mat-option>

                <mat-option *ngIf="
                    form.get('defenceDigitalService')?.value !== 'No' &&
                    form.get('defenceDigitalService')?.value !== 'Email Sent' &&
                    form.get('defenceDigitalService')?.value !== 'Not sent'
                  " value="Accepted" disabled>Accepted</mat-option>
                <mat-option *ngIf="
                    form.get('defenceDigitalService')?.value !== 'Accepted' &&
                    form.get('defenceDigitalService')?.value !== 'Email Sent' &&
                    form.get('defenceDigitalService')?.value !== 'Not sent'
                  " value="No" disabled>Not Accepted</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
            <h2 class="title m-0 qqmeTxt_2">Employer Info</h2>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-3">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Employer</mat-label>
              <input matInput formControlName="employer" required />
              <mat-error *ngIf="
                  form.get('employer').hasError('required') ||
                  form.get('employer').hasError('whitespace')
                ">
                Employer is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Phone</mat-label>
              <input matInput type="text" formControlName="employerPhone" />
              <!-- <mat-error *ngIf="
                  form.get('employerPhone').hasError('required')
                ">
                Phone is required and cannot be empty or whitespace.
              </mat-error> -->

              <mat-error *ngIf="form.get('employerPhone').hasError('phone')">
                Phone must contain only numbers.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Fax</mat-label>
              <input matInput type="text" formControlName="employerFax" />
              <mat-hint align="start" style="color: #084a91; font-weight: 500">Optional</mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Address</mat-label>
              <input matInput formControlName="employerAddress" required />
              <mat-error *ngIf="
                  form.get('employerAddress').hasError('required') ||
                  form.get('employerAddress').hasError('whitespace')
                ">
                Address is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>City</mat-label>
              <input matInput formControlName="employerCity" required />
              <mat-error *ngIf="
                  form.get('employerCity').hasError('required') ||
                  form.get('employerCity').hasError('whitespace')
                ">
                City is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>State</mat-label>
              <mat-select formControlName="employerState">
                <mat-option *ngFor="let state of states" [value]="state">{{
                  state
                  }}</mat-option>
              </mat-select>
              <mat-error *ngIf="
                  form.get('employerState').hasError('required') ||
                  form.get('employerState').hasError('whitespace')
                ">
                State is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>ZIP</mat-label>
              <input matInput formControlName="employerZip" required maxlength="5" />

              <mat-error *ngIf="
                  form.get('employerZip').hasError('required') ||
                  form.get('employerZip').hasError('whitespace')
                ">
                ZIP is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="employerEmail" />
              <!-- <mat-error *ngIf="
                  form.get('employerEmail').hasError('required') 
                ">
                Email is required.
              </mat-error> -->
              <!-- <mat-error *ngIf="form.get('employerEmail').hasError('email')">
                Please enter a valid email address.
              </mat-error> -->
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Mode of Transmission</mat-label>
              <mat-select formControlName="employermodeOfTransmission">
                <mat-option value="Email">Email</mat-option>
                <mat-option value="USPS">USPS</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Digital service confirmation</mat-label>
              <mat-select formControlName="employerDigitalService">
                <mat-option *ngIf="
                    form.get('employerDigitalService')?.value !== 'Accepted' &&
                    form.get('employerDigitalService')?.value !== 'No'
                  " value="Email Sent">Send an acceptance link</mat-option>
                <mat-option *ngIf="
                    form.get('employerDigitalService')?.value !== 'Accepted' &&
                    form.get('employerDigitalService')?.value !== 'No' &&
                    form.get('employerDigitalService')?.value !== 'Email Sent'
                  " value="Not sent">Not sent an acceptance link</mat-option>

                <mat-option *ngIf="
                    form.get('employerDigitalService')?.value !== 'No' &&
                    form.get('employerDigitalService')?.value !==
                      'Email Sent' &&
                    form.get('employerDigitalService')?.value !== 'Not sent'
                  " value="Accepted" disabled>Accepted</mat-option>
                <mat-option *ngIf="
                    form.get('employerDigitalService')?.value !== 'Accepted' &&
                    form.get('employerDigitalService')?.value !==
                      'Email Sent' &&
                    form.get('employerDigitalService')?.value !== 'Not sent'
                  " value="No" disabled>Not Accepted</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
            <h2 class="title m-0 qqmeTxt_2">Insurance company info</h2>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-3">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Insurance Company</mat-label>
              <input matInput type="text" formControlName="insuranceCompany" required />

              <mat-error *ngIf="
                  form.get('insuranceCompany').hasError('required') ||
                  form.get('insuranceCompany').hasError('whitespace')
                ">
                Insurance Company is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Insurance Company Phone</mat-label>
              <input matInput type="text" formControlName="insuranceCompanyPhone" />

              <!-- <mat-error *ngIf="form.get('insuranceCompanyPhone').hasError('required')">
                Insurance Company Phone is required.
              </mat-error>
              <mat-error *ngIf="
                  form.get('insuranceCompanyPhone').hasError('phone') &&
                  (form.get('insuranceCompanyPhone').touched ||
                    form.get('insuranceCompanyPhone').dirty)
                ">
                Phone must contain only numbers.
              </mat-error> -->
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Payer ID</mat-label>
              <input matInput type="text" formControlName="payerId" />
              <!-- <mat-error *ngIf="
                  form.get('payerId').hasError('required') ||
                  form.get('payerId').hasError('whitespace')
                ">
                PayerId is required and cannot be empty or whitespace.
              </mat-error> -->
            </mat-form-field>
            <!-- <mat-form-field appearance="outline" fxFlex="auto">
                <mat-label>Employer Payer ID</mat-label>
                <input matInput type="text" formControlName="companyPayerId" />
              </mat-form-field> -->

            <!-- <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Mode of Transmission</mat-label>
              <mat-select formControlName="insurancemodeOfTransmission">
                <mat-option value="USPS">USPS</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Adjuster</mat-label>
              <input matInput type="text" formControlName="adjuster" required />
              <mat-error *ngIf="
                  form.get('adjuster').hasError('required') ||
                  form.get('adjuster').hasError('whitespace')
                ">
                Adjuster is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Adjuster Address</mat-label>
              <input matInput type="text" formControlName="insuranceAddress" required />
              <mat-error *ngIf="form.get('insuranceAddress').hasError('required')">
                Adjuster Address is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="insuranceCity" required />
              <mat-error *ngIf="form.get('insuranceCity').hasError('required')">
                City is required.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>State</mat-label>
              <mat-select formControlName="insuranceState" required>
                <mat-option *ngFor="let state of states" [value]="state">{{
                  state
                  }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('insuranceState').hasError('required')">
                State is required and cannot be empty or whitespace.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Zip Code</mat-label>
              <input matInput type="text" formControlName="insuranceZip" maxlength="5" required />
              <mat-error *ngIf="form.get('insuranceZip').hasError('required')">
                Zip Code is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Adjuster Phone</mat-label>
              <input matInput type="text" formControlName="insurancePhone" required />
              <mat-error *ngIf="form.get('insurancePhone').hasError('required')">
                Insurance Phone is required.
              </mat-error>
              <mat-error *ngIf="
                  form.get('insurancePhone').hasError('phone') &&
                  (form.get('insurancePhone').touched ||
                    form.get('insurancePhone').dirty)
                ">
                Phone must contain only numbers.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Adjuster Fax</mat-label>
              <input matInput type="text" formControlName="insuranceFax" />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Adjuster Email</mat-label>
              <input matInput type="text" formControlName="insuranceEmail" />
              <!-- <mat-error *ngIf="form.get('insuranceEmail').hasError('required')">
                Insurance Email is required.
              </mat-error> -->
              <!-- <mat-error *ngIf="form.get('insuranceEmail').hasError('email')">
                Please enter a valid email address.
              </mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Adjuster Assistant Email</mat-label>
              <input matInput type="text" formControlName="insuranceAssistant" />
              <!-- <mat-error *ngIf="form.get('insuranceAssistant').hasError('required')">
                Adjuster Assistant Email is required.
              </mat-error> -->
              <!-- <mat-error *ngIf="form.get('insuranceAssistant').hasError('email')">
                Please enter a valid email address.
              </mat-error> -->
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-2">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Digital service confirmation</mat-label>

              <mat-select formControlName="insuranceDigitalService">
                <mat-option *ngIf="
                    form.get('insuranceDigitalService')?.value !== 'Accepted' &&
                    form.get('defenceDigitalService')?.value !== 'No'
                  " value="Email Sent">Send an acceptance link</mat-option>
                <mat-option *ngIf="
                    form.get('insuranceDigitalService')?.value !== 'Accepted' &&
                    form.get('insuranceDigitalService')?.value !== 'No' &&
                    form.get('insuranceDigitalService')?.value !== 'Email Sent'
                  " value="Not sent">Not sent an acceptance link</mat-option>

                <mat-option *ngIf="
                    form.get('insuranceDigitalService')?.value !== 'No' &&
                    form.get('insuranceDigitalService')?.value !==
                      'Email Sent' &&
                    form.get('insuranceDigitalService')?.value !== 'Not sent'
                  " value="Accepted" disabled>Accepted</mat-option>
                <mat-option *ngIf="
                    form.get('insuranceDigitalService')?.value !== 'Accepted' &&
                    form.get('insuranceDigitalService')?.value !==
                      'Email Sent' &&
                    form.get('insuranceDigitalService')?.value !== 'Not sent'
                  " value="No" disabled>Not Accepted</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-3">
            <div formArrayName="insuranceClaimNo">
              <div *ngFor="
                  let claimControl of insuranceClaimNoArray.controls;
                  let i = index
                ">
                <mat-form-field appearance="outline" fxFlex="auto">
                  <mat-label>Insurance Claim No {{ i + 1 }}</mat-label>
                  <input matInput type="text" [formControlName]="i" />
                  <mat-error *ngIf="
                      claimControl.hasError('required') && claimControl.touched
                    ">
                    Insurance Claim No is required.
                  </mat-error>
                </mat-form-field>
                <button mat-icon-button class="qqmeIcon-2" type="button" (click)="removeClaimNumber(i)"
                  *ngIf="insuranceClaimNoArray.length > 1">
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <button mat-stroked-button class="qqmeIcon-2" matTooltip="Add Claim Number" type="button"
            (click)="addClaimNumber()">
            + Add Claim Number
          </button>

          <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
            <h2 class="title m-0 qqmeTxt_2">Billing submission details</h2>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0" class="mt-6">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Bill submission Email</mat-label>
              <input matInput type="text" formControlName="billingEmail" required />

              <mat-error *ngIf="
                  form.get('billingEmail').hasError('required')
                ">
                Email is required and cannot be empty or whitespace.
              </mat-error>
              <mat-error *ngIf="
                  form.get('billingEmail').hasError('email') &&
                  (form.get('billingEmail').touched ||
                    form.get('billingEmail').dirty)
                ">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Bill submission Fax</mat-label>
              <input matInput type="text" formControlName="billingFax" />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Bill submission Address</mat-label>
              <input matInput type="text" formControlName="billingAddress" />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Bill review phone</mat-label>
              <input matInput type="text" formControlName="billingReviewPhone" />
              <mat-error *ngIf="
              form.get('billingReviewPhone').hasError('phone') &&
              (form.get('billingReviewPhone').touched ||
                form.get('billingReviewPhone').dirty)
            ">
                Phone must contain only numbers.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>Bill review email</mat-label>
              <input matInput type="text" formControlName="billingReviewEmail" />
              <mat-error *ngIf="
              form.get('billingReviewEmail').hasError('email') &&
              (form.get('billingReviewEmail').touched ||
                form.get('billingReviewEmail').dirty)
            ">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>
          </div> -->

          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" style="margin: 10px">
            <button mat-raised-button class="primary-button" type="submit" matTooltip="Submit"
              [disabled]="form.invalid || isSubmitting">
              <span *ngIf="!isSubmitting">Submit</span>
              <span *ngIf="isSubmitting"><mat-spinner diameter="20"></mat-spinner>Submitting...</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>